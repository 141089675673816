import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { TfiReload } from "react-icons/tfi";
import Tooltip from '@mui/material/Tooltip';
import LiquidationTableRow from './LiquidationTableRow';
import { useUsdEthPrice, useMarkPrice, useSelectedPage, useItemsPage, useActiveTab, useOrder } from 'store';
import useMarkPriceContractCall from './useMarkPriceContractCall';
import { IncDecTriangle } from 'components/Common';
import { sizeSort } from './utils/filters';
import { useSelectedMarket } from 'store';
import { getTokenLabel } from 'config/marketMapping';

interface LiquidationTablePageProps {
    tableData: any[];
    pageLen: any;
    itemLen: any;
    loading: boolean;
    onLoadMore: (page: any, limit: any, activeTab: any, order: any) => void;
}

interface Filters {
    orderBy: boolean;
    sizeSorted: 0 | 1 ;
    sortType: 'currentmr' | 'none';
}
const LiquidationTablePage: React.FC<LiquidationTablePageProps> = ({
    tableData,
    pageLen,
    itemLen,
    loading,
    onLoadMore,
}) => {
    const [currentData, setCurrentData] = useState<Array<any>>();
    //const [selectedPage, setSelectedPage] = useState(0);
    const { selectedPage, setSelectedPage } = useSelectedPage();
    const [tooltipTitle, setTooltipTitle] = useState('Refresh to fetch latest data');
    const { usdEthPrice } = useUsdEthPrice();
    const { markPrice, setMarkPrice } = useMarkPrice();
    const { markPrice: contractMarkPrice, refetchMarketPrice }: any = useMarkPriceContractCall();
    const { itemsPerPage, setItemsPerPage } = useItemsPage();
    const [filters, setFilters] = useState<Filters>({
        orderBy: true,
        sizeSorted: 0,
        sortType: 'none'
    });
    //const [activeTab, setActiveTab] = useState<'liquidatable' | 'underwater'>('liquidatable');
    const { activeTab, setActiveTab } = useActiveTab();
    const { direction, setOrder } = useOrder();
    useEffect(() => {
        if (!loading && tableData) {
            setCurrentData(tableData);
        }
        // if (filters.sizeSorted != 'NONE') {
            
        //     //tableData?.sort((a: any, b: any):any => sizeSort(a, b, filters.sizeSorted, filters.sortType));
        //     console.log("sort called",tableData,filters.sizeSorted);
        //     //setCurrentData(tableData);
        // }
        //console.log("filterData",filterData,startingDateValues.firstEpochValue,startingDateValues.secondEpochValue);
        
    }, [tableData, loading]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (refetchMarketPrice) {
                refetchMarketPrice();
            }
        }, 30_000);

        return () => clearInterval(intervalId);
    }, [refetchMarketPrice]);

    useEffect(() => {
        setMarkPrice(contractMarkPrice || 0);
    }, [contractMarkPrice, setMarkPrice]);

    const handlePageClick = (data: any) => {
        const selectedPage = data.selected;
        console.log("handlePageClick",selectedPage)
        setSelectedPage(data.selected);
        
        onLoadMore(selectedPage, itemsPerPage, activeTab, direction);
        console.log("handlePageClick after",selectedPage);
    };
    const handleOrderChanged = async() => {
        const selectedOrder = (direction === 'desc')? 'asc' : 'desc';
        console.log("handleOrderChanged",selectedOrder)
        setOrder(selectedOrder);
        
        onLoadMore(selectedPage, itemsPerPage, activeTab, selectedOrder);
        console.log("handleOrderChanged after",direction);
    };
    
    useEffect(() => {
        //console.log("selectedPage",selectedPage);  
        const intervalId = setInterval(() => {   
            console.log("Interval selectedPage",selectedPage, itemsPerPage, activeTab, direction);         
                onLoadMore(selectedPage, itemsPerPage, activeTab, direction);
        }, 5_000);

        return () => clearInterval(intervalId);
    }, [selectedPage, itemsPerPage, activeTab, direction]);

    const handleRefetch = () => {
        console.log("refetch click selectedPage", selectedPage, itemsPerPage);
        onLoadMore(selectedPage, itemsPerPage, activeTab, direction);
    };

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newItemsPerPage = parseInt(event.target.value, 10);
        console.log("newItemsPerPage", newItemsPerPage);
        setItemsPerPage(newItemsPerPage);
        //setSelectedPage(0);
        onLoadMore(selectedPage, newItemsPerPage, activeTab, direction);
    };

    const { selectedMarket } = useSelectedMarket();
    const tokenlabel = getTokenLabel(selectedMarket);

    return (
            <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="border-solid border-[#4B4A5E] text-[#BDB4F5] text-[20px] font-medium overflow-hidden w-full">
                <h2 className="text-2xl font-bold mb-4">Liquidation Dashboard</h2>
                <div className="bg-[#1a1b23] rounded-lg overflow-hidden">
                    <div className="p-4 flex justify-between items-center border-b border-[#4B4A5E]">
                        <div className="flex items-center gap-4">
                            
                        <button 
                            onClick={() => setActiveTab('liquidatable')}
                            className={`px-4 py-2 rounded-t-lg ${
                                activeTab === 'liquidatable' 
                                ? 'bg-[#2c2d3a] text-white font-bold' 
                                : 'bg-[#1a1b23] text-[#BDB4F5] hover:bg-[#2c2d3a]'
                            }`}
                        >
                            Liquidatable Accounts
                        </button>
                        <button 
                            onClick={() => 
                                setActiveTab('underwater')}
                            className={`px-4 py-2 rounded-t-lg ${
                                activeTab === 'underwater' 
                                ? 'bg-[#2c2d3a] text-white font-bold' 
                                : 'bg-[#1a1b23] text-[#BDB4F5] hover:bg-[#2c2d3a]'
                            }`}
                        >
                            Underwater Accounts
                        </button>
                            {/* <span className="text-[15px]">Liquidatable Accounts</span> */}
                            <Tooltip title={tooltipTitle} placement="top-start">
                                <span className={`cursor-pointer ${loading ? 'animate-spin' : ''}`} onClick={handleRefetch}>
                                    <TfiReload />
                                </span>
                            </Tooltip>
                        </div>
                        <div className="flex items-center gap-4">
                        {selectedMarket !== 'btc' && (
                            <span className="text-[14px] font-normal text-[#E8E8E8] opacity-60">
                                1 {tokenlabel} = ${usdEthPrice?.toFixed(2) || 'N/A'}
                            </span>
                            )}
                            <span className="text-[14px] font-normal text-[#E8E8E8] opacity-60">
                                Mark Price = {markPrice?.toFixed(2) || 'N/A'} {tokenlabel} 
                            </span>
                            <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                                className="bg-[#2c2d3a] text-[#B0B1C5] text-[14px] px-2 py-1 rounded border border-[#4B4A5E] focus:outline-none focus:ring-2 focus:ring-[#6C63FF] focus:border-transparent"
                            >
                                <option value={10}>10 per page</option>
                                <option value={20}>20 per page</option>
                                <option value={30}>30 per page</option>
                            </select>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <div className="overflow-y-auto max-h-[calc(100vh-300px)]">
                            <table className="w-full min-w-[1400px]">
                                <thead className="sticky top-0 bg-[#1a1b23] z-10">
                                    <tr className="border-b border-[#4B4A5E]">
                                        <th className="py-3 px-4 text-left text-[#B0B1C5] w-1/6">User</th>
                                        <th className="py-3 px-4 text-left text-[#B0B1C5] w-1/6">Position</th>
                                        <th className="py-3 px-4 text-left text-[#B0B1C5] w-1/6">Price</th>
                                        <th className="py-3 px-4 text-left text-[#B0B1C5] w-1/12">Side</th>
                                        <th className="py-3 px-4 text-left text-[#B0B1C5] w-1/6">
                                        <div
                                                className="flex justify-start items-center gap-[3px] cursor-pointer"
                                                onClick={() => {handleOrderChanged()}}
                                            >
                                                Current Mr
                                                <IncDecTriangle />
                                            </div>
                                        </th>
                                        <th className="py-3 px-4 text-left text-[#B0B1C5] w-1/6">Margin</th>
                                        <th className="py-3 px-4 text-left text-[#B0B1C5] w-1/6">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData && currentData.length > 0 ? (
                                        loading ? (
                                            Array.from({ length: itemsPerPage }).map((_, index) => (
                                                <LiquidationTableRow
                                                    key={`loading-${index}`}
                                                    item={null}
                                                    loading={true}
                                                />
                                            ))
                                        ) : (
                                            currentData.map((item) => (
                                                <LiquidationTableRow
                                                    key={item?.id || `item-${item?.user}`}
                                                    item={item}
                                                    loading={false}
                                                />
                                            ))
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={7} className="text-center py-4 text-gray-500">
                                                {loading ? 'Loading...' : 'No data available'}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {pageLen > 0 && (
                    <div className="flex justify-center mt-4">
                        <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageCount={pageLen}
                            renderOnZeroPageCount={undefined}
                            containerClassName="flex gap-2"
                            pageLinkClassName="px-3 py-1 rounded bg-[#2c2d3a] text-[#B0B1C5] hover:bg-[#3a3b47]"
                            previousLinkClassName="px-3 py-1 rounded bg-[#2c2d3a] text-[#B0B1C5] hover:bg-[#3a3b47]"
                            nextLinkClassName="px-3 py-1 rounded bg-[#2c2d3a] text-[#B0B1C5] hover:bg-[#3a3b47]"
                            activeLinkClassName="bg-[#3a3b47]"
                            forcePage={selectedPage}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiquidationTablePage;