import { API_RESOURCES } from 'config/constants';
import { prependForwardSlash } from 'utils';
import { getBaseApiUrl } from 'config/constants';
import { useSelectedMarket } from 'store';
import axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { useEffect, useRef } from 'react';

const baseAxiosConfig: CreateAxiosDefaults<any> = {
    // timeout: 5000
};


// export const liquidationSystemApi = axios.create({
//     baseURL: `${prependForwardSlash(BASE_API_URL)}${prependForwardSlash(API_RESOURCES.LIQUIDATION)}`,
//     ...baseAxiosConfig
// });

export const useLiquidationSystemApi = (): AxiosInstance => {
    const { selectedMarket } = useSelectedMarket();
    const apiClientRef = useRef<AxiosInstance>(axios.create(baseAxiosConfig));

    useEffect(() => {
        const baseURL = `${prependForwardSlash(getBaseApiUrl(selectedMarket))}${prependForwardSlash(API_RESOURCES.LIQUIDATION)}`;
        apiClientRef.current = axios.create({
            baseURL,
            ...baseAxiosConfig
        });
       // console.log("Updated baseURL:", baseURL);
    }, [selectedMarket]);

    //console.log("Api Client", apiClientRef.current);
    return apiClientRef.current;
};

