import { useAccount, useContractRead, useNetwork } from 'wagmi';
import { OrderBook } from 'assets/abi';
import { CHAIN_ID, CONTRACT_ADDRESSES } from 'config/constants';
import { formatEther } from 'viem'; 
import { useSelectedMarket } from 'store';

/**
 * Custom React hook that fetches the mark price from the OrderBook contract.
 * @returns {Object} An object containing the fetched mark price and a function to manually refetch the mark price.
 */
const useMarkPriceContractCall = () => {
    const { selectedMarket } = useSelectedMarket();
    const ORDERBOOK_ADDRESS = CONTRACT_ADDRESSES[CHAIN_ID][selectedMarket].OrderBook;
    const { chain } = useNetwork();
    const { isConnected } = useAccount();

    try {
        const { data, refetch } = useContractRead({
            watch: false,
            address: ORDERBOOK_ADDRESS,
            functionName: 'getMarkPrice',
            abi: OrderBook,
            staleTime: 20_000,
            cacheTime: 40_000,
            enabled: isConnected && chain?.id === CHAIN_ID
        });
        return { markPrice: data && +formatEther(( data as bigint)), refetchMarketPrice: refetch };
    } catch (err) {
        console.log(err);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return { markPrice: null, refetchMarketPrice: () => {} };
    }
};

export default useMarkPriceContractCall;
