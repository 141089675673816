import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { DashboardPage } from './pages';
import Navbar from 'components/Navbar';

import { DashboardDetailsPage }  from './pages';
import { useSelectedMarket } from 'store';
import  useManageRoute  from './services/api/hooks/useManageRoute';

function App() {
    const { selectedMarket } = useSelectedMarket();
    const [isLiteMode, setIsLiteMode] = useState(window.matchMedia('(max-width: 1024px)').matches);
    const navigate = useNavigate();
    const { isDashboardPage, navigateToDashboardPage } = useManageRoute();
    
    useEffect(() => {
        if (selectedMarket) {
            // Redirect to the new trade page whenever selectedMarket changes
            navigateToDashboardPage();
        }
    }, [selectedMarket]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1024px)');

        const handleMediaQueryChange = (e: any) => {
            setIsLiteMode(e.matches);
        };

        mediaQuery.addListener(handleMediaQueryChange);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    

    

    return (
        <>
            {/* {isLiteMode ? (<div>
                
                </div>
            ) : ( */}
                <div>
                    
                    
                        <Navbar/>
                        <Routes>
                            <Route path="/" element={<Navigate to={`/dashboard/${selectedMarket}`} replace />} />
                            <Route path={`dashboard/${selectedMarket}/*`} element={<DashboardPage />}>
                                {/* Set default route for 'dashboard/snv' */}
                                <Route index element={<DashboardDetailsPage />} />
                            </Route>
                        </Routes>
                </div>
                
            {/* )} */}
        </>
    );
}

export default App;
