import NavbarLeft from './components/NavbarLeft';
import NavbarRight from './components/NavbarRight';
//import { useRibbonContext } from '../../context/RibbonContext/RibbonContext';
import SearchBar from './components/SearchBar';

const Navbar = () => {
    //const ribbonContext = useRibbonContext();

    return (
        <nav
            className="flex justify-between items-center bg-blivBg-950 h-[60px] text-blivTxt-950 px-6 border-b border-solid border-blivNavBorder-950"
        >
            <NavbarLeft />
            <div className="flex-grow max-w-[40rem]">
                <SearchBar />
            </div>
            <NavbarRight />
        </nav>
    );
};

export default Navbar;
