import { DetailsWithTooltip } from 'components/Common';
import { useWindowSize } from 'utils';
import LiquidationPage from '../LiquidationPage/LiquidationPage';
import { useEffect } from 'react';
import useMarkPriceContractCall from './useMarkPriceContractCall';
import axios from 'axios';
import { useUsdEthPrice, useMarkPrice } from 'store';
import { useSelectedMarket } from 'store';
import { getTokenLabel } from 'config/marketMapping';

const Details = () => {
    const {setUsdEthPrice, usdEthPrice} = useUsdEthPrice();
    const { setMarkPrice } = useMarkPrice();
    const { markPrice, refetchMarketPrice }: any = useMarkPriceContractCall();
    //const [ethUsd, setEthUsdPrice] = useState(0);
    const marketPrice = markPrice ? markPrice : 0.0;
    setInterval(() => {
        if (refetchMarketPrice) {
            refetchMarketPrice();
        }
    }, 30_000);

    useEffect(() => {
        setMarkPrice(marketPrice);
    }, [marketPrice]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                `https://api.coingecko.com/api/v3/simple/price?ids=${'ethereum'}&vs_currencies=${'usd'}`,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*'
                    }
                }
                );
                const price = res?.data['ethereum']['usd'];
                setUsdEthPrice(price);
            } catch (_) {
                console.error('Error fetching floor price');
            }
        };

        fetchData();
    }, []);
    const { selectedMarket } = useSelectedMarket();
    const tokenlabel = getTokenLabel(selectedMarket);
    //console.log("totalLongPos totalShortPos",totalLongPos,totalShortPos)
    return (    
        <div className="w-[100%] min-w-[1300px] p-6 pl-50 theme-gradient rounded-[8px] pb-4">
                {/* <div className="text-[18px] font-inter text-white">Liquidation</div> */}
                <div className="w-full h-[1px] bg-[#ffffff75] opacity-80 my-4" />
                <div className="grid grid-cols-2 4xl:grid-cols-6 gap-4">
                    <div className="p-6 pt-[14px] w-full h-[110px] bg-[#10141B] rounded-[10px]">                        
                        <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                    {"$ "+usdEthPrice}
                        </div>
                        <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                            USD/ETH                        
                            <span className="mt-1">
                                <DetailsWithTooltip tooltipText={"USD/ETH"} />
                            </span>
                        </div>
                        
                    </div>
                    <div className="p-6 pt-[14px] w-full h-[110px] bg-[#10141B] rounded-[10px]">                        
                        <div className="text-white text-[20px] font-inter mt-3 mb-1 truncate flex justify-start items-baseline">
                                {marketPrice.toFixed(2) + tokenlabel}
                        </div>
                        <div className="text-[#E8E8E8] text-[14px] font-inter flex justify-start items-center gap-1 ">
                            Mark Price                        
                            <span className="mt-1">
                                <DetailsWithTooltip tooltipText={"Mark Price"} />
                            </span>
                        </div>
                    </div>
                </div>
        </div>
            
    );
};
const DashboardDetailsPage = () => {
    const maxWidth = 679;
    const { windowInnerWidth } = useWindowSize();
    return (
        <div>
            {!windowInnerWidth ? null : windowInnerWidth >= maxWidth ? (
                <div className="flex gap-4 p-[1rem] flex-col">
                                       
                    <div className="flex w-full gap-y-2">
                        <LiquidationPage />
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 p-[1rem]">
                    {/* <LiquidationPage />                     */}
                </div>
            )}
        </div>
    );
};

export default DashboardDetailsPage;
