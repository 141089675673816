import { prependForwardSlash } from 'utils';
import { polygon, polygonMumbai, mainnet, goerli, sepolia, baseSepolia, base } from 'wagmi/chains';


export const IS_MAINNET = false;//Boolean(process.env.REACT_APP_IS_MAINNET);
export const SG_API_URL = IS_MAINNET
    ? 'https://api.thegraph.com/subgraphs/name/hridyansh-khatri/nftfn'
    : 'https://api.thegraph.com/subgraphs/name/gulshanvas/bliv-internal';
export const ERROR_MESSAGE = 'Something went wrong!!';
export const BASE_API_URL = IS_MAINNET ? 'https://api.nftfn.xyz/v1/' : 'https://testnet.api.nftfn.xyz/v1/';
export const WS_BASE_API_URL = IS_MAINNET ? 'wss://api.nftfn.xyz/v1/' : 'wss://testnet.api.nftfn.xyz/v1/';
export const BASE_API_URL_V2 = IS_MAINNET ? 'https://api.nftfn.xyz/v2/' : 'https://testnet.api.nftfn.xyz/v2/';
export const CHAIN_ID = IS_MAINNET ? 8453 : 84532;
export const TOKEN_CLAIM_IS_MAINNET = true;//Boolean(process.env.REACT_APP_TOKEN_CLAIM_IS_MAINNET);

export const PLYGON_ERROR_SCAN_URL = IS_MAINNET
    ? 'https://polygonscan.com/tx/'
    : 'https://mumbai.polygonscan.com/tx/';
export const ALCHEMY_TESTNET_ID = process.env.ALCHEMY_TESTNET_ID || 'yVl1ec1e5mFb9ck4LIZoV26lXNq74CLm';
export const ALCHEMY_ID = process.env.ALCHEMY_ID || '9pYSfA6q4BQHZuOpcTxPyIDsWRVWcEY4';
export const WALLETCONNECT_PROVIDER_ID =
    process.env.REACT_APP_WALLETCONNECT_PROVIDER_ID || '9a61364b672be3dca77888a9637dde74';
export const API_RESOURCES = {
    LIQUIDATION: 'market'
};


export const CONTRACT_ADDRESSES: { [id: number]: { [market: string]: { [id: string]: `0x${string}` } } } = {
    [mainnet.id]: {
        default : {            
            OrderBook: '0x17e259558030ca001071ec1c9eea025151ca6e8a',
            Margin: '0xa7dba83e86ef8ff29c48f3c9a2229928a5ebafe3',
            ProtocolVault: '0x88887e54bc575ffdd7cb5fdf0802819b76e3974d',
            Oracle: '0x100faed7ffd2cb5c6b9f37e1bd6b51adacf34427'
        }
    },
    [goerli.id]: {
        default : {
            OrderBook: '0x17e259558030ca001071ec1c9eea025151ca6e8a',
            Margin: '0xa7dba83e86ef8ff29c48f3c9a2229928a5ebafe3',
            ProtocolVault: '0x88887e54bc575ffdd7cb5fdf0802819b76e3974d',
            Oracle: '0x100faed7ffd2cb5c6b9f37e1bd6b51adacf34427'
        }
    },
    [polygon.id]: {
        default :{
            OrderBook: '0x25702b254FE8b30e9C087E644d6fEd1bC3Ae82c6',
            Margin: '0x24932A3E4c632c22931838C36c41C63c5F417A1E',
            ProtocolVault: '0x1282aB44B7082b0c4676b3c52D987cbb7220F7bA',
            InsuranceFund: '0x01e095CDD652c25C2cE0fC501F9c1D7d848B124F',
            Oracle: '0xe2D601ABb218531c800f7553167f6738F4b2C2d8'
        }        
    },
    [polygonMumbai.id]: {
        default : {
            OrderBook: '0x6330FF71e1F0F5D00A00765638084AE291F55B91',
            Margin: '0x08Ee7b84959C295e1BcAFDeaf582CA8637728Bc3',
            ProtocolVault: '0x1b7302DD8edEbDe87F1bee976BF9dA89dD6EBD9A',
            Oracle: '0x5a1D83aE7dDbf7955952F13eBf1206a8208FBC59',
            InsuranceFund: '0x2B549380119bb25F1E517E74C1C1Aac7C653fFcF'
        }        
    },
    [baseSepolia.id]: {
        snv : {
            InsuranceFund: '0x0DaDD63c21e01b4E1829557F5bcb53256E2F3F5d',
            BalanceMath: '0xa62786AAD1a75189bb063dA086640Eca83a7E21F',
            ProtocolVault: '0x3ba2d963d66F360c613a096ceE7b030A832f9c69',
            LiquidationHelper: '0xB18AC1A668a1F58475d154F63CaddEd42200FeF4',
            OrderBook: '0x357cb1BD462704d1bcaBB46a2Cc3815c0C83E22d',
            Margin: '0xd245134530FCebe15cA9884e7F5F19cdf317eDBb',
            Oracle: '0x4eED0b937556a2dd93D092C5235a7aE78A50c882'
        },
        btc : {
            InsuranceFund: '0x0DaDD63c21e01b4E1829557F5bcb53256E2F3F5d',
            BalanceMath: '0xa62786AAD1a75189bb063dA086640Eca83a7E21F',
            ProtocolVault: '0x3ba2d963d66F360c613a096ceE7b030A832f9c69',
            LiquidationHelper: '0xB18AC1A668a1F58475d154F63CaddEd42200FeF4',
            OrderBook: '0x357cb1BD462704d1bcaBB46a2Cc3815c0C83E22d',
            Margin: '0xd245134530FCebe15cA9884e7F5F19cdf317eDBb',
            Oracle: '0x4eED0b937556a2dd93D092C5235a7aE78A50c882'
        }
        
    }
};

export const PRESALE_CONTRACT_ADDRESSES: { [id: number]: { [id: string]: `0x${string}` } } = {
    [mainnet.id]: {
        PreSale: '0x5F3eD79A3EF34e1c4d6D02b0A8C297D8d79115eb'
    },
    [sepolia.id]: {
        PreSale: '0x825181Cc677D66B581311a5a0e3b3101C9456189'
    },
};

export const LIQUIDATE_CONTRACT_ADDRESSES: { [id: number]: { [id: string]: `0x${string}` } } = {
    [mainnet.id]: {
        Liquidate: '0x5F3eD79A3EF34e1c4d6D02b0A8C297D8d79115eb'
    },
    [baseSepolia.id]: {
        Liquidate: '0x825181Cc677D66B581311a5a0e3b3101C9456189'
    },
};

export const TOKENS_ADDRESSES: { [id: number]: { [id: string]: `0x${string}` } } = {
    [mainnet.id]: {
        WETH: '0xdBCa78Ef5BB4d5DD6475680E0275D397FD17E98A',
        MATIC: '0x0000000000000000000000000000000000001010',
        USDC: '0x2e3A2fb8473316A02b8A297B982498E661E1f6f5',
        USDT: '0x56eCEAEB5aA36BEC3De2b3d2a3f3CDBfA98c44a2'
    },
    [goerli.id]: {
        WETH: '0xdBCa78Ef5BB4d5DD6475680E0275D397FD17E98A',
        MATIC: '0x0000000000000000000000000000000000001010',
        USDC: '0x2e3A2fb8473316A02b8A297B982498E661E1f6f5',
        USDT: '0x56eCEAEB5aA36BEC3De2b3d2a3f3CDBfA98c44a2'
    },
    [polygon.id]: {
        WETH: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        MATIC: '0x0000000000000000000000000000000000001010',
        USDC: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        USDT: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
    },
    [polygonMumbai.id]: {
        WETH: '0xdBCa78Ef5BB4d5DD6475680E0275D397FD17E98A',
        MATIC: '0x0000000000000000000000000000000000001010',
        USDC: '0x2e3A2fb8473316A02b8A297B982498E661E1f6f5',
        USDT: '0x56eCEAEB5aA36BEC3De2b3d2a3f3CDBfA98c44a2'
    },
    [base.id]: {
        WETH: '0xdBCa78Ef5BB4d5DD6475680E0275D397FD17E98A',
        MATIC: '0x0000000000000000000000000000000000001010',
        USDC: '0x2e3A2fb8473316A02b8A297B982498E661E1f6f5',
        USDT: '0x56eCEAEB5aA36BEC3De2b3d2a3f3CDBfA98c44a2'
    },
    [baseSepolia.id]: {
        WETH: '0xA3842e1625FaeF792f61eBD3c3bEaE2132564BcC',
        USDT: '0x4fCF9298DBC2788866f60Bf26F60b9F309ca2Ad8'
    }
};

export const cryptocurrencies = [    
    { id: 'btc', name: 'BTC-USD', symbol: 'BTC' },
    { id: 'snv', name: 'SNV-WETH', symbol: 'SNV' },
    // { id: 'ethereum', name: 'Ethereum', symbol: 'ETH' },
    // { id: 'ripple', name: 'Ripple', symbol: 'XRP' },
    // { id: 'cardano', name: 'Cardano', symbol: 'ADA' },
    // { id: 'dogecoin', name: 'Dogecoin', symbol: 'DOGE' },
    // { id: 'polkadot', name: 'Polkadot', symbol: 'DOT' },
    // { id: 'litecoin', name: 'Litecoin', symbol: 'LTC' },
    // { id: 'stellar', name: 'Stellar', symbol: 'XLM' },
    // { id: 'chainlink', name: 'Chainlink', symbol: 'LINK' },
    // { id: 'tether', name: 'Tether', symbol: 'USDT' }
];
export const getBaseApiUrl = (market: string): string => {
    if (IS_MAINNET) {
        // Mainnet URL (common for all markets)
        return 'https://api.nftfn.xyz/v1/';
    } else {
        // Testnet URLs based on the market
        switch (market.toLowerCase()) {
            case 'btc':
                return 'https://testnet.btc.api.nftfn.xyz/v1/';
            case 'snv':
                return 'https://testnet.api.nftfn.xyz/v1/';
            default:
                throw new Error(`Unsupported market: ${market}`);
        }
    }
};

